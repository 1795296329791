<script setup lang="ts">
import { ref } from 'vue'

const locations = [
  {
    id: 'uae',
    country: 'United Arab Emirates',
    city: 'Dubai',
    address: 'Office 102, 613 Al Manama St, Belresheed-3, Ras Al Khor, Dubai, UAE',
    email: 'office@stride.ae',
    phone: '+971 4 225 3555',
    icon: '🇦🇪'
  },
  {
    id: 'egypt',
    country: 'Egypt',
    city: 'Alexandria',
    address: '1st Floor, Kamarayet Roushdy Towers Building, Kamarayet Roushdy, Alexandria Governorate 21529, Egypt',
    email: 'egypt@stride.ae',
    phone: '+971 4 225 3555',
    icon: '🇪🇬'
  }
]

const activeLocation = ref('')

const toggleLocation = (id: string) => {
  activeLocation.value = activeLocation.value === id ? '' : id
}
</script>

<template>
  <div class="space-y-6 max-w-3xl mx-auto">
    <div v-for="location in locations" :key="location.id" 
         class="group relative">
      <div class="absolute inset-0 bg-gradient-to-r from-accent/20 to-accent-dark/20 rounded-xl blur-xl transition-opacity duration-500"
           :class="activeLocation === location.id ? 'opacity-100' : 'opacity-0'"></div>
      
      <div class="relative bg-secondary/80 backdrop-blur-lg rounded-xl overflow-hidden border border-white/5 hover:border-accent/30 transition-all duration-300">
        <button 
          @click="toggleLocation(location.id)"
          class="w-full px-8 py-6 flex items-center gap-4 text-white hover:bg-white/5 transition-colors"
        >
          <span class="text-3xl">{{ location.icon }}</span>
          <div class="flex-1 text-left">
            <h3 class="text-xl font-semibold text-white group-hover:text-accent transition-colors">
              {{ location.city }}
            </h3>
            <p class="text-blue-300/80">{{ location.country }}</p>
          </div>
          <span class="transform transition-transform duration-300" 
                :class="activeLocation === location.id ? 'rotate-180' : ''">
            <svg class="w-6 h-6 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </span>
        </button>
        
        <div v-show="activeLocation === location.id"
             class="px-8 pb-6 text-center animate-fadeIn">
          <p class="text-gray-300 mb-4">{{ location.address }}</p>
          <div class="flex items-center justify-center gap-6">
            <div class="flex items-center gap-2 text-gray-300">
              <svg class="w-5 h-5 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              <span>{{ location.email }}</span>
            </div>
            <div class="flex items-center gap-2 text-gray-300">
              <svg class="w-5 h-5 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
              </svg>
              <span>{{ location.phone }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.animate-fadeIn {
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}
</style>