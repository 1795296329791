<script setup lang="ts">
import { loadFull } from "tsparticles"
import type { Engine } from "tsparticles-engine"

async function particlesInit(engine: Engine) {
  await loadFull(engine)
}
</script>

<template>
  <Particles
    id="tsparticles"
    :particlesInit="particlesInit"
    :options="{
      fullScreen: false,
      background: {
        color: {
          value: 'transparent'
        }
      },
      fpsLimit: 60,
      particles: {
        color: {
          value: '#3B82F6'
        },
        links: {
          color: '#3B82F6',
          distance: 150,
          enable: true,
          opacity: 0.2,
          width: 1
        },
        move: {
          enable: true,
          speed: 1,
          direction: 'none',
          random: true,
          straight: false,
          outModes: {
            default: 'bounce'
          },
          attract: {
            enable: true,
            rotateX: 600,
            rotateY: 1200
          }
        },
        number: {
          value: 60,
          density: {
            enable: true,
            value_area: 800
          }
        },
        opacity: {
          value: 0.2,
          random: true,
          animation: {
            enable: true,
            speed: 1,
            minimumValue: 0.1,
            sync: false
          }
        },
        size: {
          value: 3,
          random: true,
          animation: {
            enable: true,
            speed: 2,
            minimumValue: 0.5,
            sync: false
          }
        }
      },
      detectRetina: true
    }"
  />
</template>

<style scoped>
#tsparticles {
  @apply w-full h-full;
}
</style>