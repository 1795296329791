<script setup lang="ts">
import { ref } from 'vue'
import { useInterval } from '@vueuse/core'

const phrases = [
  'Innovating AI Solutions',
  'Transforming Businesses',
  'Creating Tomorrow',
  'Launching Soon'
]

const currentPhrase = ref(phrases[0])
const currentIndex = ref(0)

useInterval(3000, { 
  callback: () => {
    currentIndex.value = (currentIndex.value + 1) % phrases.length
    currentPhrase.value = phrases[currentIndex.value]
  }
})
</script>

<template>
  <div class="max-w-2xl mx-auto mb-12">
    <div class="relative bg-secondary/30 backdrop-blur-sm rounded-2xl p-8 border border-accent/20">
      <div class="absolute inset-0 bg-gradient-to-r from-accent/5 to-accent-dark/5 rounded-2xl"></div>
      <div class="relative">
        <div class="flex flex-col items-center justify-center space-y-4">
          <div class="text-3xl font-bold text-accent animate-pulse-slow">
            {{ currentPhrase }}
          </div>
          <p class="text-blue-200/80 text-center">
            Experience the future of business technology. Our innovative AI solutions are coming to revolutionize your enterprise.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.animate-pulse-slow {
  animation: pulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>